(($) => {
  "use strict";

  $(() => {
    $("#monova-carousel")
      .imagesLoaded()
      .always(() => {
        $("#monova-slider-images").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: false,
          swipe: false,
          arrows: false,
          dots: false,
        });
        $("#monova-slider-title").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: "#monova-slider-images",
          draggable: false,
          swipe: false,
          arrows: true,
          dots: true,
          fade: true,
          arrowsPlacement: "beforeSlides",
          autoplay: true,
          autoplaySpeed: 5000,
        });
        $("#monova-slider-images").slick("slickGoTo", 0);
      });
  });
})(jQuery);
